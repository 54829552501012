import { FC, useEffect } from 'react';
import Newsletter from '../../Layouts/Newsletter';
import {
    Outlet, Link
} from "react-router-dom";
 
const Home: FC = () => {  
    return (
        <>  
             <div className="hero-wrap">
                <div className="home-slider owl-carousel">
                <div className="slider-item" style={{ backgroundImage: 'url(assets/images/slider_1.jpg)' }}>
                    <div className="overlay"></div>
                    <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-8 ftco-animate">
                            <div className="text w-100 text-center">
                                <h2>INVESTMENT CLUB</h2>
                                <h1 className="mb-4">Nous sommes un groupe d'hommes et de femmes engagés dans l'investissement</h1>
                                <p><Link to="/a-propos" className="btn btn-white">A propos de nous</Link></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item" style={{ backgroundImage: 'url(assets/images/slider_2.jpg)' }}>
                    <div className="overlay"></div>
                    <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-8 ftco-animate">
                            <div className="text w-100 text-center">
                                <h2> INVESTMENT CLUB </h2>
                                <h1 className="mb-4">Rejoignez nous dès aujourd'hui, ensemble nous le pouvons</h1>
                                <p><Link to="/devenir-un-membre" className="btn btn-white">Débuter votre pré-inscription dès maintenant</Link></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

        
                </div>
            </div>
            
            <section className="ftco-section ftco-no-pt bg-light">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <div className="col-md-6 d-flex">
                            <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center 
                            justify-content-md-center mb-4 mb-sm-0" style={{ backgroundImage: 'url(assets/images/money_tree.jpg)' }}>
                            </div>
                        </div>
                        <div className="col-md-6 pl-md-5 py-md-5">
                            <div className="heading-section pl-md-4 pt-md-5">
                                <span className="subheading">Bienvenue chez INVESTMENT CLUB</span>
                        <h2 className="mb-4">La formation et l'investissement sont les grands pôles de notre association.</h2>
                            </div>

                            <div className="services-2 w-100 d-flex">
                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-teamwork"></span></div>
                                <div className="text pl-4">
                                    <h4>Formation d'investisseurs</h4>
                                    <p>Nous proposons des formations enfin de vous lancer dans le domaine de l'investissement</p>
                                </div>
                            </div>


                            <div className="services-2 w-100 d-flex">
                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-wealth"></span></div>
                                <div className="text pl-4">
                                    <h4> Soutien aux projets </h4>
                                    <p>
                                        Nous soutenons des projets dans nos communautés à travers nos propres fonds.
                                    </p>
                                </div>
                            </div>

                            <div className="services-2 w-100 d-flex">
                                <div className="text pl-4"> 
                                    <Link to="/devenir-un-membre" className="btn btn-primary">Débuter votre pré-inscription dès maintenant</Link>
                                </div> 
                            </div>
        
                    </div>
                </div>
                </div>
            </section>
        

            <section className="ftco-counter bg-light ftco-no-pt" id="section-counter">
                <div className="container">
                    <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                        <b> Notre association en chiffre </b></h2>
                        <div className="row">
                <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18 text-center">
                    <div className="text">
                        <strong className="number" data-number="1">0</strong>
                    </div>
                    <div className="text">
                        <span>années d'existance</span>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18 text-center">
                    <div className="text">
                        <strong className="number" data-number="250">0</strong>
                    </div>
                    <div className="text">
                        <span>Membres actifs</span>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18 text-center">
                    <div className="text">
                        <strong className="number" data-number="200">0</strong>
                    </div>
                    <div className="text">
                        <span>Formations disponibles</span>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18 text-center">
                    <div className="text">
                        <strong className="number" data-number="51">0</strong>
                    </div>
                    <div className="text">
                        <span>Projets réalisés</span>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </section>
        
                

            {/* <section className="ftco-section ftco-no-pb ftco-no-pt bg-secondary">
            <div className="container py-5">
                    <div className="row">
                <div className="col-md-7 d-flex align-items-center">
                    <h2 className="mb-3 mb-sm-0" style={{ color:'black', fontSize: '22px' }}>
                        S'inscrire à notre newsletter </h2>
                </div>
                <div className="col-md-5 d-flex align-items-center">
                    <form action="#" className="subscribe-form">
                    <div className="form-group d-flex">
                        <input type="text" className="form-control" placeholder="Entrez votre adresse email" />
                        <input type="submit" value="S'inscrire" className="submit px-3" />
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </section> */}

            {/* <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row justify-content-center pb-5 mb-3">
                <div className="col-md-7 heading-section text-center ftco-animate">
                    <span className="subheading"> Notre marché </span>
                    <h2> Nos offres de formation </h2>
                </div>
                </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 ftco-animate">
                    <div className="block-7">
                        <div className="text-center">
                            <span className="excerpt d-block">Personal</span>
                        <span className="price"><sup>$</sup> <span className="number">49</span> <sub>/mos</sub></span>
                        
                        <ul className="pricing-text mb-5">
                        <li><span className="fa fa-check mr-2"></span>Brand Strategy</li>
                        <li><span className="fa fa-check mr-2"></span>Online Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Branding Services</li>
                        <li><span className="fa fa-check mr-2"></span>Creative Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Sales Management</li>
                        </ul>

                        <a href="#" className="btn btn-primary d-block px-2 py-3">
                            Acheter
                        </a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ftco-animate">
                    <div className="block-7">
                        <div className="text-center">
                        <span className="excerpt d-block">Business</span>
                        <span className="price"><sup>$</sup> <span className="number">79</span> <sub>/mos</sub></span>
                        
                        <ul className="pricing-text mb-5">
                        <li><span className="fa fa-check mr-2"></span>Brand Strategy</li>
                        <li><span className="fa fa-check mr-2"></span>Online Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Branding Services</li>
                        <li><span className="fa fa-check mr-2"></span>Creative Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Sales Management</li>
                        </ul>

                        <a href="#" className="btn btn-primary d-block px-2 py-3">
                            Acheter
                        </a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ftco-animate">
                    <div className="block-7">
                        <div className="text-center">
                            <span className="excerpt d-block">Ultimate</span>
                        <span className="price"><sup>$</sup> <span className="number">109</span> <sub>/mos</sub></span>
                        
                        <ul className="pricing-text mb-5">
                        <li><span className="fa fa-check mr-2"></span>Brand Strategy</li>
                        <li><span className="fa fa-check mr-2"></span>Online Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Branding Services</li>
                        <li><span className="fa fa-check mr-2"></span>Creative Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Sales Management</li>
                        </ul>

                        <a href="#" className="btn btn-primary d-block px-2 py-3">
                            Acheter
                        </a>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3 ftco-animate">
                    <div className="block-7">
                        <div className="text-center">
                            <span className="excerpt d-block">Premium</span>
                        <span className="price"><sup>$</sup> <span className="number">149</span> <sub>/mos</sub></span>
                        
                        <ul className="pricing-text mb-5">
                        <li><span className="fa fa-check mr-2"></span>Brand Strategy</li>
                        <li><span className="fa fa-check mr-2"></span>Online Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Branding Services</li>
                        <li><span className="fa fa-check mr-2"></span>Creative Marketing</li>
                        <li><span className="fa fa-check mr-2"></span>Sales Management</li>
                        </ul>

                        <a href="#" className="btn btn-primary d-block px-2 py-3">
                            Acheter
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section> */}

            <Newsletter />
        </>
    );
}

export default Home
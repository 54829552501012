import { FC, useEffect, useState } from 'react';
 
import {
    Outlet, Link
} from "react-router-dom";

import Banner from '../../Layouts/Banner';
import Newsletter from '../../Layouts/Newsletter';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import ConnexionService from './service';


const Connexion: FC = () => {  

    const connexionService = new ConnexionService();

    const countries_init: any = [];
    const country_phone_code_init: string = '';

    const [
        countries, setCountries
    ] = useState(countries_init);

    const [
        countryPhoneCode, setCountryPhoneCode
    ] = useState(country_phone_code_init);

    const [
        loadCountries, setLoadingCountries
    ] = useState(false);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        showSuccessDiv, setShowSuccessDiv
    ] = useState(false);

    const getCountries = () => {

        setLoadingCountries(true);

        connexionService.getCountries().then(async function (response: any) {
            console.log(response); 
            setCountries(response.data.countries);
            setLoadingCountries(false);  
        })
        .catch(function (error: any) {
            console.log(error);
            alert( 'Une erreur s\'est produite réesseyez s\'il vous plaît' )
            // setLoadingCountries(false); 
        });

    }


    useEffect(function() {
        getCountries();
    }, [])


    return (
        <>  
            <Banner activePage={"Connexion membre"} />
                <section className="ftco-section ftco-no-pt bg-light">
                <br />
                
                { !showSuccessDiv && <div className="container">
                    {/* <h2 style={{ textAlign: 'center' }} className="mb-4 center">
                        <b> Connexion membre </b></h2>
                        <p style={{ textAlign: 'center' }}>
                            Fournissez votre Email et votre mot de passe puis valider
                        </p> */}
                    <div className='row'> 
                        <div className='col-md-2'> </div> 
                        <div className='col-md-8'>  
                    <div  className="contact-wrap w-100 p-md-5 p-4" >

                    <p className='text-center' style={{ textAlign: 'center' }}>
                        Vous avez déjà un compte membre chez nous connectez-vous rapidement sur notre plateforme sécurisée
                        <a href='https://dev.client.aaic-global.org/'
                                    type="button" className="btn btn-primary btn-block" >
                                        Se connecter
                                        {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                        </a>
                    </p>

                        

                    {/* <Formik
                                    initialValues={
                                        {    
                                            email: '',
                                            password: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            password: yup
                                                .string() 
                                                .required('Ce champ est obligatoire'), 
                                            email: yup
                                                .string()  
                                                .required('Ce champ est obligatoire')
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {
                                        setLoading(true);

                                        var formValue  = values;

                                       
                                      }}  
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form className=" row contactForm" >  
                                    <div className="form-group col-md-12">
                                        <label htmlFor="">Email</label>
                                        <input className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                                                id="email" 
                                                                                name="email"
                                                                                onChange={(event: any) => { 
                                                                                    handleChange('email')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('email')}
                                                                                value={values.email == null ? ''  :
                                                                                values.email }
                                                                                placeholder="Email" />
                                                                            { errors.email && touched.email &&
                                                                            errors.email && 
                                                                        <div id="validationServer05Feedback" 
                                                                        className="invalid-feedback">
                                                                                { errors.email && touched.email &&
                                                                                errors.email }
                                                                        </div> }
                                    </div>
         

                            <div className="form-group col-md-12">
                                <label htmlFor="">Mot de passe</label>
                                <input className={`form-control ${ errors.password && touched.password ? "is-invalid":""}`}
                                                                        id="password" 
                                                                        name="password"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('password')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        type='password'
                                                                        onBlur={handleBlur('password')}
                                                                        value={values.password == null ? ''  :
                                                                         values.password }
                                                                        placeholder="Mot de passe" />
                                                                    { errors.password && touched.password &&
                                                                     errors.password && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.password && touched.password &&
                                                                         errors.password }
                                                                </div> }
                            </div>

                            <br />
                            <br />
                            <br />
                            <br />

                            <div className="col-md-12">
                                <div className="form-group">
                                    <button disabled={(!dirty && !isValid) || countryPhoneCode === '' } 
                                    type="submit" className="btn btn-primary btn-block" >
                                        Se connecter
                                        {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                    </button>
                                    <div className="submitting"></div>
                                </div>
                            </div>

                            <br />
                            <br />

                        
 
                        </Form>
                                    )}
                </Formik> */}
                <p className='text-center' style={{ textAlign: 'center' }}>Vous n'avez pas encore de compte membre ? créez en un simplement ici
                    <Link to='/devenir-un-membre'
                                    type="button" className="btn btn-primary btn-block" >
                                        Créer un compte
                                        {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                    </Link>
                </p>
                 

                    </div>
                    </div>
                    </div> 
                </div> }
            </section>
        </>
    );
}

export default Connexion